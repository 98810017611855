/* eslint-disable indent */
import { InsuranceRisksValues } from '@pulse-smart-components-kit/insurance-risks';
import type { RisksResponse } from './insurance-risks-adapter.types';

export const getOptionsValue = (
  data: RisksResponse['risks'],
  value?: InsuranceRisksValues
) => ({
  risks: !!value?.risks?.length
    ? data.map((risk) => ({
        ...risk,
        active: value.risks.find(({ code }) => code === risk.code)
          ? true
          : risk.active,
      }))
    : data,
  isValid: true,
});
