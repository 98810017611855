import type { ParsePresetData } from '@shared/types';
import React, { memo } from 'react';
import { useAppConfigs } from './use-app-configs';
import { FormBuilder } from '@features/form-builder';
import { DEFAULT_APP_CONFIG } from 'mock';

interface Props {
  parsePresetData: ParsePresetData;
}

export const AppConfig = memo(({ parsePresetData }: Props) => {
  const components = useAppConfigs(DEFAULT_APP_CONFIG, parsePresetData);

  return <FormBuilder components={components} />;
});

AppConfig.displayName = 'AppConfig';
