import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ThemeContainerIn } from './theme-container-in';
import { useStores } from '@shared/hooks';

type Props = {
  children: ReactNode;
};

export const ThemeContainer: FC<Props> = ({ children }) => {
  const {
    MainStore: {
      themesStore: { setPresetData },
    },
  } = useStores();

  const [searchParams] = useSearchParams();
  const [themeUrl, setThemeUrl] = useState<string | undefined>();
  const [presetUrl, setPresetUrl] = useState<string | undefined>();

  useEffect(() => {
    setThemeUrl(searchParams.get('personalization') || undefined);
    setPresetUrl(searchParams.get('preset') || undefined);
  }, []);

  return (
    <ThemeContainerIn
      themeUrl={themeUrl}
      presetUrl={presetUrl}
      onPresetLoaded={setPresetData}
    >
      {children}
    </ThemeContainerIn>
  );
};
