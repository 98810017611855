import { SmartFooter } from '@entities/import-smart-components/smart-footer';
import { Suspense, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';
import { FallbackSkeleton } from '@shared/components';
import type { FC } from 'react';
import { footerPeriodNames } from '@shared/constants';
import { FooterApartmentValue } from '@pulse-smart-components-kit/footer-apartment';
import { initPrice } from '@entities/stores';
import { SelectedDuration } from '@shared/types';

export const FooterAdapter: FC = observer(() => {
  const {
    MainStore: {
      productStore: {
        price,
        hasPricesCalcErr,
        formState: { InsurancePeriodAndLimits, InsuranceRisks },
      },
    },
  } = useStores();

  const period = InsurancePeriodAndLimits?.period;

  const value = useMemo(() => {
    const periodValue = period || price.defaultContractDuration;

    const priceByDuration = price.prices?.find(
      (period) => period.duration === periodValue
    );

    const priceValue: FooterApartmentValue = {
      price: initPrice,
      period: periodValue
        ? footerPeriodNames[periodValue as SelectedDuration]
        : '',
    };

    if (priceByDuration) {
      priceValue.price = {
        promoCode: price.promoCode,
        premiumAndDelta: priceByDuration.premiumAndDelta,
        premiumAndDeltaPromo: priceByDuration.premiumAndDeltaPromo,
        isSuccessfulPromo: price.isSuccessfulPromo,
      };
    }

    return priceValue;
  }, [period, price]);

  const isHideFooterPrices =
    hasPricesCalcErr ||
    InsurancePeriodAndLimits?.isValid === false ||
    InsuranceRisks?.isValid === false;

  return (
    <Suspense fallback={<FallbackSkeleton height={60} />}>
      <SmartFooter value={value} hasPricesCalcErr={isHideFooterPrices} />
    </Suspense>
  );
});

FooterAdapter.displayName = 'FooterAdapter';
