import type { IFLFlatProductConfig } from '../types';

export const DEFAULT_APP_CONFIG: IFLFlatProductConfig = {
  name: 'flat-base-flow',
  header: [
    {
      name: 'Header',
      id: 'Header',
    },
  ],
  footer: [
    {
      name: 'Footer',
      id: 'Footer',
    },
  ],
  submit: [
    {
      name: 'Submit',
      id: 'Submit',
    },
  ],
  flow: [
    {
      icon: 'LocationPin',
      name: 'address',
      components: [
        {
          name: 'InsuranceAddress',
          id: 'InsuranceAddress',
        },
      ],
    },
    {
      icon: 'Bedroom',
      name: 'calculation',
      components: [
        {
          name: 'InsurancePeriodAndLimits',
          id: 'InsurancePeriodAndLimits',
        },
        {
          name: 'InsuranceRisks',
          id: 'InsuranceRisks',
        },
      ],
    },
    {
      icon: 'PersonFilled',
      name: 'formalization',
      components: [
        {
          name: 'WhoIssuesPolicy',
          id: 'WhoIssuesPolicy',
        },
        {
          name: 'StartDate',
          id: 'StartDate',
        },
      ],
    },
    {
      icon: 'Wallet',
      name: 'payment',
      components: [
        {
          name: 'CheckPolicy',
          id: 'CheckPolicy',
        },
        {
          name: 'B2P',
          id: 'B2P',
        },
      ],
    },
  ],
  globalVariables: {
    otpStep: 3,
    isShowBackButton: true,
  },
};
