/* eslint-disable @typescript-eslint/no-explicit-any */
import { useStorageQuery } from './use-storage-query';

export const usePresetRequest = <T>(
  productName: string,
  presetPartner?: string,
  presetName?: string
) => {
  const queryResult = useStorageQuery<T>(
    'presetRequest',
    `/presets-arch-vzr/${presetPartner}/${productName}/${presetName}.json`,
    [presetPartner, presetName],
    {
      enabled: !!presetPartner && !!presetName,
      staleTime: 100_000_000,
    }
  );

  const { isLoading, error, data, refetch, isFetching, isRefetching, status } =
    queryResult;

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return {
    isLoading,
    error,
    res,
    refetch,
    isFetching,
    isRefetching,
    status,
  };
};
