import type {
  FormBuilderStructure,
  ParsePresetData,
  SmartComponent,
} from '@shared/types';
import type {
  FlowConfig,
  ProductConfig,
  StepConfig,
  FlatGlobalVariables,
} from 'mock';
import { useEffect, useState } from 'react';

import {
  initState,
  getSmartComponents,
  getCommonComponents,
  mergeAgentHeaderConfig,
} from './use-app-configs.helpers';
import type { HeaderStep } from '@pulse-smart-components-kit/header-base';
import { useStores } from '@shared/hooks';

export const useAppConfigs = <T extends FlatGlobalVariables>(
  defaultConfig: ProductConfig<T>,
  parsePresetData: ParsePresetData
) => {
  const {
    MainStore: {
      applicationStore: { setFlowConfig },
      productStore: { agentLogin },
      themesStore: { appConfig, presetData },
    },
  } = useStores();

  const [components, setComponents] = useState<FormBuilderStructure>(initState);
  const [flowConfigLocal, setFlowConfigLocal] = useState<FlowConfig<any>>({});
  const config = appConfig || defaultConfig;

  // Место для мёрджа конфигов: дефолтного, темы, персонализации и входных данных с маркетинговой страницы.
  useEffect(() => {
    const body: SmartComponent<any>[] = [];
    const headerConfig: HeaderStep[] = [];
    const stepConfig: StepConfig[] = [];

    config.flow.forEach((flow, flowIndex) => {
      const localStep = getSmartComponents({
        flow,
        flowIndex,
        parsePresetData,
        presetData,
      });
      body.push(...localStep.components);
      headerConfig.push(localStep.header);
      stepConfig.push(localStep.steps);
    });

    const smartComponents: FormBuilderStructure = {
      header: getCommonComponents(config.header),
      footer: getCommonComponents(config.footer),
      submit: getCommonComponents(config.submit),
      body,
    };

    setComponents(smartComponents);
    setFlowConfigLocal({
      headerConfig: mergeAgentHeaderConfig(headerConfig, agentLogin),
      stepConfig,
    });
  }, [config, presetData]);

  useEffect(() => {
    setFlowConfig({
      ...config.globalVariables,
      maxSteps: config.flow.length,
      ...flowConfigLocal,
    });
  }, [config.globalVariables, flowConfigLocal]);

  return components;
};
