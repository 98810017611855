/* eslint-disable indent */
import { useHandleErrorRetry, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import type { FC } from 'react';
import {
  useHandleQueries,
  useHandleMobileParams,
  useHandleTerritory,
  useHandleProduct,
  useInsuranceLimitStart,
  useGetAgeRatio,
  useHandleSessionRecovery,
} from './hooks';
import { useGetPrice } from '@shared/queries';
import { useHandleGetSubobjects } from '@entities/adapters/insurance-period-and-limits-adapter/queries';
import { useHandleGetRisks } from '@entities/adapters/insurance-risks-adapter/hooks';

export const InitBox: FC = observer(() => {
  const {
    MainStore: {
      applicationStore: { setLoading },
      initProductStore: { setInitState, initState },
      errorStore: { setErrorRetry },
    },
  } = useStores();

  useHandleQueries();
  useHandleMobileParams();
  const { isLoadingCountry, resCountry, refetchCountry } = useHandleTerritory();
  const refetchProduct = useHandleProduct();
  const { resAgeRatio, isLoadingAgeRation, refetchAgeRatio } = useGetAgeRatio();
  const {
    resInsuranceLimitStart,
    isLoadingInsuranceLimitStart,
    refetchInsuranceLimit,
  } = useInsuranceLimitStart();
  const {
    data: subobjectsData,
    isLoading: isLoadingSubobjects,
    refetch: refetchSubobjects,
  } = useHandleGetSubobjects();
  const {
    data: risksData,
    isLoading: isLoadingRisks,
    refetch: refetchRisks,
  } = useHandleGetRisks();
  const refetchGetPrice = useGetPrice();

  //TODO: Добавить лоадер при восстановлении ссесси, в рамках задачи [WEB] Добавление лоадеров на шаги и компоненты (https://pulse-insure.atlassian.net/browse/PROD-11571)
  useHandleSessionRecovery();

  useEffect(() => {
    if (!isLoadingCountry && resCountry) {
      setInitState({
        ...initState,
        ...resCountry,
      });
      setLoading(false);
      setErrorRetry(false);
    }
  }, [isLoadingCountry, resCountry]);

  useEffect(() => {
    if (!isLoadingInsuranceLimitStart && resInsuranceLimitStart) {
      setErrorRetry(false);
    }
  }, [isLoadingInsuranceLimitStart, resInsuranceLimitStart]);

  useEffect(() => {
    if (!isLoadingAgeRation && resAgeRatio) {
      setErrorRetry(false);
    }
  }, [isLoadingAgeRation, resAgeRatio]);

  useEffect(() => {
    if (!isLoadingSubobjects && subobjectsData) {
      setErrorRetry(false);
    }
  }, [isLoadingSubobjects, subobjectsData]);

  useEffect(() => {
    if (!isLoadingRisks && risksData) {
      setErrorRetry(false);
    }
  }, [isLoadingRisks, risksData]);

  const selectRefetch = (index: string) => {
    switch (index) {
      case 'INSURANSE_LIMIT_START':
        return refetchInsuranceLimit();
      case 'GET_INSURANCE_PRODUCT':
        return refetchProduct();
      case 'GET_AGE_RATION':
        return refetchAgeRatio();
      case 'TERRITORY':
        return refetchCountry();
      case 'GET_PRICES':
        return refetchGetPrice();
      case 'GET_SUBOBJECTS':
        return refetchSubobjects();
      case 'GET_RISKS':
        return refetchRisks();
    }
  };

  useHandleErrorRetry(selectRefetch);

  return null;
});
