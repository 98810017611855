import { useEffect } from 'react';

import { Endpoints, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';

import {
  AuthBoxAnalyticEvent,
  type AuthenticationResponseType,
  type OnAnalyticEventSend,
} from '../auth-box.type';
import { hasError, ErrorCode } from '@shared/utils';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '@shared/routes';

export const useHandleConfirmAuthentication = (
  isLoadingAuthentication?: boolean,
  resAuthentication?: AuthenticationResponseType,
  onAnalyticEventSend?: OnAnalyticEventSend
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        callOtp,
        authenticationToken,
        profile,
        confirmationId,
        setDisableForm,
        setOtpIsLoading,
        setOtpErrorCode,
        handleOtpResponse,
      },
    },
  } = useStores();

  const navigate = useNavigate();

  const requestData = {
    code: callOtp,
    confirmationId: resAuthentication?.confirmationId,
  };

  const { isLoading, error, res, refetch, remove } = useRequest(
    QUERY_KEYS.confirmAuth,
    'post',
    Endpoints.CONFIRMAUTH,
    requestData,
    [callOtp, resAuthentication?.authenticationToken],
    true,
    authenticationToken
  );

  useEffect(() => {
    const shouldRefetchOtp =
      callOtp &&
      !profile &&
      confirmationId &&
      resAuthentication &&
      authenticationToken &&
      !isLoadingAuthentication;

    if (shouldRefetchOtp) {
      setDisableForm(true);
      refetch();
    }
  }, [
    profile,
    callOtp,
    confirmationId,
    resAuthentication,
    authenticationToken,
    isLoadingAuthentication,
  ]);

  useEffect(() => {
    setOtpIsLoading(isLoading);

    if (!isLoading) {
      if (res && res.accessToken) {
        handleOtpResponse(res);
        setOtpErrorCode(false);
        setErrorRetry(false);
        onAnalyticEventSend &&
          onAnalyticEventSend(AuthBoxAnalyticEvent.ON_ENTER_CODE_SUCCESS);
      }
      if (hasError(error, ErrorCode.UnknownUserOrCode)) {
        setOtpErrorCode(true);
      }
      if (hasError(error, ErrorCode.RateLimit)) {
        setOtpErrorCode(true);
        navigate(PublicRoutes.AUTHORIZE_FAIL);
      }
    }
  }, [isLoading, error, res]);

  return {
    isLoadingConfirmAuthentication: isLoading,
    errorConfirmAuthentication: error,
    resConfirmAuthentication: res,
    refetchConfirmAuthentication: refetch,
    removeConfirmAuthentication: remove,
  };
};
