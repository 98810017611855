import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';
import { TechnicalScreen } from '@pulse-web-ui/technical-screen';
import { RGSNoWIFI } from '@pulse-web-ui/icons';

type GlobalErrorType = {
  showErr: boolean;
};

export const GlobalError = observer(({ showErr }: GlobalErrorType) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
    },
  } = useStores();
  const { t } = useTranslation();
  if (!showErr) return;

  const errorHandler = () => {
    setErrorRetry(true);
  };

  return (
    <TechnicalScreen
      icon={<RGSNoWIFI width={80} />}
      title={t('COMMON:headers.globalError')}
      subtitle={t('COMMON:labels:tryAgain')}
      isShow={showErr}
      buttonLabel={t('COMMON:labels:update')}
      onButtonClick={errorHandler}
    />
  );
});
