import { useEffect, useState } from 'react';

import type { FieldErrors } from 'react-hook-form';

type Props = {
  errors: FieldErrors;
  submitCount: number;
  wantNextStep: boolean;
  isShowOtp: boolean;
};

export const useDisabledSubmit = ({
  errors,
  submitCount,
  wantNextStep,
  isShowOtp,
}: Props): boolean => {
  const [disabled, setDisabled] = useState(false);
  const hasErrors = Boolean(Object.keys(errors).length);

  useEffect(() => {
    if (disabled) {
      setDisabled(hasErrors);
    }
  }, [hasErrors]);

  useEffect(() => {
    if (hasErrors) {
      setDisabled(true);
    }
  }, [submitCount]);

  useEffect(() => {
    if (isShowOtp) {
      setDisabled(hasErrors);
    }
  }, [isShowOtp, hasErrors]);

  useEffect(() => {
    if (wantNextStep) {
      setDisabled(false);
    }
  }, [wantNextStep]);

  return disabled;
};
