import { useRequest, useStores } from '@shared/hooks';
import { Endpoints } from '@shared/constants';
import { useEffect, useMemo } from 'react';
import { PublicRoutes } from '@shared/routes';
import { useNavigate } from 'react-router-dom';
import { ErrorCode } from '@shared/utils';
import { Risk } from '@pulse-smart-components-kit/insurance-risks';

export const useHandleGetSubobjects = () => {
  const {
    MainStore: {
      initProductStore: { setInitState, initState },
      authStore: { authTokens },
      productStore: { formState },
    },
  } = useStores();

  const navigate = useNavigate();

  const risks = (formState?.InsuranceRisks?.risks || initState.risks)?.map(
    (risk: Risk) => risk.code
  );

  const enabled = useMemo(() => {
    return Boolean(risks?.length) && Boolean(initState.code);
  }, [risks?.length, initState.code]);

  const {
    res: data,
    error: subobjectsError,
    refetch,
    isLoading,
  } = useRequest(
    'formIFLGetSubobjects',
    'post',
    Endpoints.GET_SUBOBJECTS,
    {
      productCode: initState.code,
      region: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5', // TODO: получать из стора

      // TODO remove hardcode
      risks: risks ? [...risks, 'Liability_1'] : [],
    },
    [initState.code, risks, authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [enabled, authTokens?.authorization?.accessToken]);

  useEffect(() => {
    if (!isLoading) {
      if (subobjectsError?.response?.data?.code === ErrorCode.UserData) {
        setInitState({});
        navigate(PublicRoutes.POLICY_BANNED);
      } else if (data) {
        setInitState({ ...initState, ...{ subobjects: data } });
      }
    }
  }, [isLoading, data, subobjectsError]);

  return { isLoading, data, refetch };
};
