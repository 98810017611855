/* eslint-disable indent */
import { usePresetRequest, useStores, useThemeRequest } from '@shared/hooks';
import { AuthFlow, PresetResponse, ThemeConfig } from '@shared/types';
import merge from 'lodash.merge';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { Spinner } from '@pulse-web-ui/spinner';
import {
  StyledThemeProvider,
  baseTheme,
  createGlobalStyle,
} from '@pulse-web-ui/theme';
import type { BaseThemeType } from '@pulse-web-ui/theme';

import { useDictionary, useFontLink } from './hooks';
import { FontContainer } from './theme-container.styles';
import { getThemeLink, getThemeURL } from './utils';
import { IFLFlatProductConfig } from 'mock';

type Props = {
  themeUrl?: string;
  presetUrl?: string;
  onPresetLoaded?: (data: PresetResponse) => void;
  children: React.ReactNode;
};

type GlobalThemeProps = {
  theme: BaseThemeType;
};

export const ThemeContainerIn = observer((props: Props) => {
  const { children, themeUrl, presetUrl, onPresetLoaded } = props;
  // themeUrl - составной параметр вида “partnername_customname”
  // первая часть partnername - название партнера (соответствующий каталог в CDN)
  // вторая часть customname - название конфига кастомизации (внутри на первом этапе два параметра с названием темы ui и названием коллекции текстов texts)
  // подробнее - в документации

  const [partnername, customname] = themeUrl?.split('_') || [];
  const [presetPartner, presetName] = presetUrl?.split('_') || [];

  const themeLink = getThemeLink(partnername, customname);
  const {
    MainStore: {
      applicationStore: { setLoading, loading },
      themesStore: { setAppConfig },
    },
  } = useStores();

  const fontLink = partnername
    ? `${window.envUrls.STORAGE_URL}/themes/${String(partnername)}/fonts`
    : '';

  const { isLoading: isLoadingConfig, res: resConfig } =
    useThemeRequest<ThemeConfig>(
      'themingRequest',
      themeLink,
      [themeLink],
      !!themeLink
    );

  const { isLoading: isLoadingPreset, res: resPreset } =
    usePresetRequest<PresetResponse>('apartment', presetPartner, presetName);

  const { isLoading: isLoadingTheme, res: resTheme } =
    useThemeRequest<ThemeConfig>(
      'theme',
      getThemeURL(resConfig, partnername, 'ui', 'theme-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'ui', 'theme-url')
    );

  const { isLoading: isLoadingIcons, res: resIcons } =
    useThemeRequest<AuthFlow>(
      'icons',
      getThemeURL(resConfig, partnername, 'icons', 'icons-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'icons', 'icons-url')
    );

  const { isLoading: isLoadingTexts, res: resTexts } =
    useThemeRequest<ThemeConfig>(
      'texts',
      getThemeURL(resConfig, partnername, 'i18n', 'texts-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'i18n', 'texts-url')
    );

  const { isLoading: isLoadingFlow, res: resFlow } =
    useThemeRequest<IFLFlatProductConfig>(
      'flow',
      getThemeURL(resConfig, partnername, 'flows', 'flow-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'flows', 'flow-url')
    );

  useEffect(() => {
    if (!isLoadingPreset && !!resPreset) {
      onPresetLoaded?.(resPreset);
    }
  }, [isLoadingPreset, resPreset, onPresetLoaded]);

  useEffect(() => {
    if (!isLoadingFlow && resFlow) setAppConfig(resFlow);
  }, [isLoadingFlow, resFlow]);

  const customTheme = useMemo(() => {
    if (!loading && resTheme) {
      return merge(baseTheme, resTheme, resIcons || {});
    }

    return baseTheme;
  }, [loading, resTheme, resIcons]);

  useFontLink(fontLink, customTheme);

  const GlobalStyle = createGlobalStyle`
    body {
      ${({ theme }: GlobalThemeProps) => {
        if (theme.common['font-url'])
          return `
          * {
            font-family: ${theme.common['font-url']}
          }`;
        else if (theme.common['font-family']) {
          return `
          * {
            font-family: ${theme.common['font-family']}
          }`;
        }
        return '';
      }};
    };
  `;

  useEffect(() => {
    setLoading(!!themeUrl);
  }, [themeUrl]);

  const isLoading =
    isLoadingConfig ||
    isLoadingTheme ||
    isLoadingIcons ||
    isLoadingTexts ||
    isLoadingFlow ||
    isLoadingPreset;

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useDictionary(resTexts, isLoadingTexts);

  //TODO: PageSpinnerWrapper необходимо ввести в компонент spinner после появления актуального дизайна
  if (loading) {
    return (
      <StyledThemeProvider theme={baseTheme}>
        <Spinner />
      </StyledThemeProvider>
    );
  }

  return (
    <StyledThemeProvider theme={customTheme}>
      <GlobalStyle />
      <FontContainer>{children}</FontContainer>
    </StyledThemeProvider>
  );
});
